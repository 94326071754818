<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title>Sélection de camping</v-card-title>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <h4 class="mb-5">
          Affichage de campings aléatoire en page d'accueil à partir de la sélection des campings ci-dessous :
        </h4>

        <p v-if="errors && errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="campingSelection"
              :items="campings"
              multiple
              outlined
              chips
              hint="Choisir un maximum de 20 campings"
              persistent-hint
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import config from '../../../config'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    Snackbar,
    Loader
  },
  data () {
    return {
      snackbar: false,
      successMessage: null,
      valid: false,
      errors: [],
      loading: false,
      campings: [],
      campingSelection: [],
      selection: null,
      hasId: false
    }
  },
  computed: {
  },
  created () {
    this.getCampings()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (this.valid) {
        this.selection.id ? this.putSelection() : this.postSelection()
      }
    },
    getCampings () {
      this.loading = true

      const params = {
        limit: 150
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/api/campings', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const campings = response.data

            for (let i = 0; i < campings.length; i++) {
              const currentCamping = campings[i]

              this.campings.push({
                text: currentCamping.name,
                value: currentCamping.id
              })
            }
          }

          this.getSelection()
        })
        .catch(() => {
          this.loading = false
        })
    },
    getSelection () {
      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/selections', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const currentSelection = response.data
            this.selection = response.data

            for (let i = 0; i < currentSelection.campings.length; i++) {
              const currentCamping = currentSelection.campings[i]

              this.campingSelection.push({
                text: currentCamping.translations.fr.name,
                value: currentCamping.id
              })
            }
          }

          this.valid = true
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    postSelection () {
      this.valid = false
      this.successMessage = null
      this.loading = true

      const selectedCamping = []

      for (let i = 0; i < this.campingSelection.length; i++) {
        const currentCamping = this.campingSelection[i]

        selectedCamping.push({
          id: currentCamping
        })
      }

      const data = {
        campings: selectedCamping
      }

      Vue.prototype.$http
        .post(config.apiUrl + '/api/selections', data, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 201) {
            this.selection = response.data
            this.errors = []
            this.snackbar = true
            this.successMessage = 'Selection mise à jour avec succès.'
            this.valid = true
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    putSelection () {
      this.valid = false
      this.successMessage = null
      this.loading = true

      const selectedCamping = []

      for (let i = 0; i < this.campingSelection.length; i++) {
        const currentCamping = this.campingSelection[i]

        selectedCamping.push({
          id: (typeof currentCamping === 'string') ? currentCamping : currentCamping.value
        })
      }

      const data = {
        id: this.selection.id,
        campings: selectedCamping
      }

      Vue.prototype.$http
        .put(config.apiUrl + '/api/selections/' + this.selection.id, data, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.errors = []
            this.snackbar = true
            this.successMessage = 'Selection mis à jour avec succès.'
            this.valid = true
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    }
  }
}
</script>
